import Users from '@/modules/users/Users';
import Restaurants from '@/modules/restaurants/Restaurants';
import Businesses from '@/modules/businesses/BusinessesPage';
import Suppliers from '@/modules/suppliers/Suppliers';
import { Products } from '@/modules/product';
import SupplierItems from '@/modules/suppliers/SupplierItems';
import PriceIndexes from '@/modules/price-indexes/PriceIndexes';
import PriceIndex from '@/modules/price-indexes/PriceIndex';
import { OperationsOverview, OperationsOverviewByBusiness } from '@/modules/operations';
import { TemplateManagement, AutomationManagementDocuments, AutomationManagement } from '@/modules/automation';
import { Requests } from '@/modules/requests/serviceProvider';
import {
  OpenRequestsServiceProvider,
  ClosedRequestsServiceProvider,
  InReviewRequestsServiceProvider,
} from '@/modules/requests';
import { DocumentsOperationOverview } from '@/modules/document';
import ContactsSetPage from '@/modules/contactSet/contactSets.vue';
import DefineProductTask from '@/modules/product/components/task/DefineProductTask.vue';
import TasksPage from '@/modules/tasks/TasksPage.vue';

const EmptyComponent = {
  render(h) {
    return h('div', ['EmptyComponent']);
  },
};

export const clarityRoutes = [
  {
    path: '/',
    name: 'clarity',
    redirect: { name: 'clarity.users' },
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      { path: 'users', name: 'clarity.users', component: Users },
      { path: 'restaurants', name: 'clarity.restaurants', component: Restaurants },
      { path: 'businesses', name: 'clarity.businesses', component: Businesses },
      { path: 'suppliers', name: 'clarity.suppliers', component: Suppliers },
      { path: 'suppliers/:supplierId', name: 'clarity.supplierItems', component: SupplierItems },
      { path: 'operation/documents', name: 'clarity.documents', component: DocumentsOperationOverview },
      { path: 'automationManagement', name: 'automationManagement', component: AutomationManagement },
      {
        path: 'automationManagement/documents',
        name: 'automationManagementDocuments',
        component: AutomationManagementDocuments,
      },
      {
        path: 'automationManagement/documents/templates',
        name: 'automationTemplateManagement',
        component: TemplateManagement,
      },
      { path: 'products', name: 'clarity.products', component: Products },
      {
        path: 'insights',
        name: 'clarity.insights',
        component: EmptyComponent,
      },
      { path: 'price-indexes', name: 'clarity.priceIndexes', component: PriceIndexes },
      { path: 'price-indexes/:priceIndexId', name: 'clarity.priceIndex', component: PriceIndex },
      { path: 'operations', name: 'operations', component: OperationsOverview },
      { path: 'operations-by-business', name: 'operationsByBusiness', component: OperationsOverviewByBusiness },
      {
        path: 'requests/',
        name: 'requests',
        component: Requests,
        redirect: { name: 'open' },
        children: [
          { path: 'open', name: 'open', component: OpenRequestsServiceProvider },
          { path: 'in-review', name: 'inReview', component: InReviewRequestsServiceProvider },
          { path: 'closed', name: 'closed', component: ClosedRequestsServiceProvider },
        ],
      },
      { path: 'contact-sets', name: 'clarity.contactSets', component: ContactsSetPage },
      { path: 'tasks/', name: 'clarity.tasks', component: TasksPage },
      { path: 'tasks/:taskId', name: 'clarity.productTask', component: DefineProductTask },
    ],
  },
];
