export const USER_PERMISSIONS_LIST = {
  'identity.permissionSource.read': 'identity.permissionSource.read',
  'identity.permissionSource.write': 'identity.permissionSource.write',
  BILLING: {
    MANAGE: 'billing.manage',
    READ: 'billing.read',
    WRITE: 'billing.write',
    EXTENDED: 'billing.extended',
  },
  PAYMENT: {
    VIEW: 'payment.view',
    READ: 'payment.read',
    WRITE: 'payment.write',
    EXTENDED: 'payment.extended',
    DOCUMENT: 'payment.document',
    INITIATE: 'payment.initiate',
    MANAGE_PAYMENT_TASKS: 'payment.manage_payments_tasks',
    EDIT_DOCUMENTED_EVENT: 'payment.edit_documented_event',
    DELETE_DOCUMENTED_EVENT: 'payment.delete_documented_event',
    MANAGE_BANK_ACCOUNTS: 'payment.manage_bank_accounts',
    MANAGE_CREDIT_CARDS: 'payment.manage_credit_cards',
    OTHER_DESTINATION: 'payment.other_destination',
  },
  PURCHASE: {
    PAYMENT_TERM_MANAGE: 'payment.term.manage',
    ORDER_EDIT: 'order.edit',
    DELIVERY_MANAGER: 'delivery.manage',
    VIEW: 'purchase.view',
  },
  'document.read': 'document.read',
  'document.write': 'document.write',
  'document.extended': 'document.extended',
  'request.read': 'request.read',
  'request.write': 'request.write',
  'payment.read': 'payment.read',
  'payment.write': 'payment.write',
  'payment.extended': 'payment.extended',
  'billing.read': 'billing.read',
  'billing.write': 'billing.write',
  'billing.extended': 'billing.extended',
  'document.view': 'document.view',
  'document.upload': 'document.upload',
  'document.bulkDownload': 'document.bulkDownload',
  'document.typeOther': 'document.typeOther',
  'document.manage': 'document.manage',
  'bookkeeping.read': 'bookkeeping.read',
  'bookkeeping.write': 'bookkeeping.write',
  'bookkeeping.extended': 'bookkeeping.extended',
  'order.read': 'order.read',
  'order.write': 'order.write',
  RECONCILIATION: {
    MANAGE: 'reconciliation.manage',
    APPROVE: 'reconciliation.approve',
    VIEW: 'reconciliation.view',
  },
  REQUEST: {
    MANAGE: 'request.manage',
    READ: 'request.read',
    WRITE: 'request.write',
  },
  'term.read': 'term.read',
  'term.write': 'term.write',
  'pur.invite.pm.orderer': 'pur.invite.pm.orderer',
  'pur.invite.manager': 'pur.invite.manager',
  'pur.invite.payments.manager:': 'pur.invite.payments.manager:',
  'pur.invite.admin': 'pur.invite.admin',
  'pur.invite.owner': 'pur.invite.owner',
};

export const USER_NEW_PERMISSIONS_LIST = {
  EDIT_ORDER_TERMS: 'order.terms.edit',
  VIEW_BUSINESS_SETTINGS: 'business_settings.view',
  VIEW_PERMISSIONS: 'business_settings.view',
  ORDER_PROCESS: 'order_process.manage',
  VIEW_PURCHASE_MANAGEMENT: 'purchase.view',
  PURCHASE_PAYMENT_TERMS: 'payment.term.manage',
  EDIT_ORDER: 'order.edit',
  EDIT_DELIVERY: 'delivery.manage',
  VIEW_DOCUMENT: 'document.view',
  EDIT_AGREEMENTS: 'agreement.edit',
  EDIT_PRODUCT: 'product.edit',
};
