<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <Button v-if="isUserPermitted" @click="showAddRelationModal = true">
        {{ $t('relations.newPermission') }}
      </Button>
    </div>
    <BusinessRelationsTable
      :is-user-permitted="isUserPermitted"
      :relations-loading="relationsLoading"
      :relations="businessRelations"
      :source-tenant="currentTenant.name"
      @deleteRelation="deleteBusinessRelation"
    />
    <CreateBusinessRelationModal
      v-if="showAddRelationModal"
      :businesses="businesses"
      :source-tenant="currentTenant.name"
      @submit="submit"
      @close="showAddRelationModal = false"
    />
  </div>
</template>

<script type="text/javascript">
import { computed, ref, getCurrentInstance } from 'vue';

import { useAllTenants } from '@/modules/core/compositions/all-tenants';
import { Button } from '@/modules/core';
import { useTenancy, useUserPermissions } from '@/modules/auth';
import { useBusinessRelationsGet } from '@/modules/permissions/compositions';

import { useBusinessRelationsCreate, useBusinessRelationsDelete } from '../compositions';
import { CreateBusinessRelationModal, BusinessRelationsTable } from '../components';

import { USER_PERMISSIONS_LIST } from '../../../permissions';

export const BUSINESS_TYPES = {
  serviceProvider: 'SERVICE_PROVIDER',
  restaurant: 'RESTAURANT',
  supplier: 'SUPPLIER',
  bookkeeping: 'BOOK_KEEPING',
};

export const BUSINESS_TYPES_NORMALIZED = {
  SERVICE_PROVIDER: 'serviceProvider',
  RESTAURANT: 'restaurant',
  SUPPLIER: 'supplier',
  BOOK_KEEPING: 'bookkeeping',
};

export default {
  name: 'BusinessRelations',
  components: { Button, CreateBusinessRelationModal, BusinessRelationsTable },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const {
      businessRelations,
      loading: relationsLoading,
      refetch: fetchRelations,
    } = useBusinessRelationsGet(computed(() => ({ providerBusinessId: currentTenant.value.id })));
    const { tenants, loading: suppliersLoading } = useAllTenants();
    const { isUserPermittedForActiveTenant } = useUserPermissions();

    const { deleteBusinessRelation, loading: deleteLoading } = useBusinessRelationsDelete(fetchRelations);
    const { createBusinessRelation, loading: createLoading } = useBusinessRelationsCreate(fetchRelations);

    const businesses = computed(() => {
      return tenants.value.reduce((newObj, tenant) => {
        if (tenant.type === BUSINESS_TYPES.serviceProvider || tenant.type === BUSINESS_TYPES.bookkeeping) {
          newObj[tenant.id] = tenant;
        }
        return newObj;
      }, {});
    });

    const mappedRelations = computed(() =>
      (businessRelations.value.nodes || []).map((item) => ({
        tenantName: item.receiverBusinessName,
        permissionType: root.$t(`BUSINESS_TYPES.${BUSINESS_TYPES[item.type]}`),
        id: item.id,
      }))
    );

    const submit = ({ selectedTenant }) => {
      const { id, type } = selectedTenant;

      const businessToBusinessRelation = {
        providerBusinessId: currentTenant.value.id,
        receiverBusinessId: id,
        type: BUSINESS_TYPES_NORMALIZED[type],
      };
      createBusinessRelation({ businessToBusinessRelation });
    };

    const isUserPermitted = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['identity.permissionSource.write']);

    return {
      isUserPermitted,
      businessRelations: mappedRelations,
      relationsLoading: computed(() => relationsLoading.value || deleteLoading.value || createLoading.value),
      deleteBusinessRelation,
      businesses: computed(() => businesses.value),
      businessesLoading: computed(() => suppliersLoading.value),
      showAddRelationModal: ref(false),
      currentTenant,
      submit,
    };
  },
};
</script>
