export function loadLegacyView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/imports/ui/${view}.vue`);
}

export function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/${view}.vue`);
}

export function getMeta(matched) {
  return matched.reduce((meta, record) => Object.assign(meta, record.meta), {});
}
