import { loadLegacyView, loadView } from './utils';

export const authRoutes = [
  {
    path: '/',
    component: loadView('auth/AuthLayout'),
    children: [
      { path: 'sign-in', name: 'signin', component: loadView('auth/SignIn') },
      { path: 'forgot-password', name: 'forgotPassword', component: loadLegacyView('pages/forgot_password') },
      { path: 'reset-password/:token', name: 'resetPassword', component: loadLegacyView('pages/reset_password') },
      { path: 'enroll-account', name: 'enroll', component: loadLegacyView('pages/enroll') },
      { path: 'not-found', name: 'notFound', component: loadLegacyView('pages/not_found') },
    ],
  },
];
