import { OperationManagement } from '@/modules/activity';
import {
  ReconciliationClosedActivity,
  ReconciliationOpenActivity,
  ReconciliationTasks,
  SupplierCreateTasks,
  UncertainBillingTasks,
  BalanceAlignmentTasks,
} from '@/modules/activity/pages';

export const operationManagementRoutes = [
  {
    path: 'activity/operation-management/',
    component: OperationManagement,
    name: 'operation-management',
    redirect: { name: 'reconciliation-open-activity' },
    children: [
      {
        path: 'reconciliation-closed-activity',
        name: 'reconciliation-closed-activity',
        component: ReconciliationClosedActivity,
      },
      {
        path: 'reconciliation-open-activity',
        name: 'reconciliation-open-activity',
        component: ReconciliationOpenActivity,
      },
      {
        path: 'reconciliation-tasks',
        name: 'reconciliation-tasks',
        component: ReconciliationTasks,
      },
      {
        path: 'supplier-create-tasks',
        name: 'supplier-create-tasks',
        component: SupplierCreateTasks,
      },
      {
        path: 'uncertain-billing-tasks',
        name: 'uncertain-billing-tasks',
        component: UncertainBillingTasks,
      },
      {
        path: 'balance-alignment-tasks',
        name: 'balance-alignment-tasks',
        component: BalanceAlignmentTasks,
      },
    ],
  },
];
