import { test } from 'ramda';
import VueRouter from 'vue-router';

const abortCallbacks = [];

VueRouter.prototype.onAbort = (callback) => abortCallbacks.push(callback);

const processAbortCallbacks = () => abortCallbacks.forEach((callback) => callback());

export default (router) => {
  const historyTransitionTo = router.history.constructor.prototype.transitionTo;

  // This method will be called for both "router.push" and "router.replace" methods under the hood
  router.history.constructor.prototype.transitionTo = function extendedTransitionTo(location, onComplete, onAbort) {
    return historyTransitionTo.call(this, location, onComplete, (error) => {
      // ignore navigation cancelled event process
      if (test(/^Navigation\scancelled/, error.message)) {
        return console.warn(error.message);
      }

      // ignore redundant navigation
      if (test(/^Avoided\sredundant\snavigation/, error.message)) {
        return console.warn(error.message);
      }

      processAbortCallbacks();
      if (onAbort) {
        onAbort(error);
      }
    });
  };
};
