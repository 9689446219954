<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <Button v-if="isUserPermitted" type="secondary" @click="showAddEditMembership = true">
        + {{ $t('memberships.membershipTable.add') }}
      </Button>
    </div>
    <MembershipManagementTable
      :memberships="memberships ? memberships : []"
      :memberships-loading="tableLoading"
      :current-tenant="currentTenant"
      :allowed-roles="allowedRoles"
      @on-select-delete="onSelectDeleteIndex"
      @on-select-edit="onSelectEditIndex"
      @on-membership-update="onMembershipUpdate"
    />
    <AddEditMembershipModal
      v-if="showAddEditMembership"
      :loading-add="isActionLoading"
      :allowed-roles="allowedRoles"
      :edit-index="editRowIndex"
      :memberships="memberships ? memberships : []"
      @on-add-edit-membership="onAddEditMembership"
      @on-close="onAddEditMembershipModalClose"
    />
    <el-dialog v-if="showDeleteDialog" :visible.sync="showDeleteDialog" width="25rem" class="delete-dialog" center>
      <template #title>
        <div class="delete-dialog-title">
          <DeleteWarningIcon />
          <h2>{{ $t('memberships.membershipTable.deletePermission.title') }}</h2>
        </div>
      </template>
      <div>
        {{
          $t('memberships.membershipTable.deletePermission.text', {
            name: `${memberships[deleteRowIndex].user.firstName} ${memberships[deleteRowIndex].user.lastName}`,
            tenant: currentTenant.name,
          })
        }}
      </div>
      <template #footer>
        <Button type="secondary" @click="showDeleteDialog = false">{{
          $t('memberships.membershipTable.deletePermission.cancel')
        }}</Button>
        <Button type="danger" :loading="isActionLoading" @click="onMembershipDelete">{{
          $t('memberships.membershipTable.deletePermission.confirm')
        }}</Button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import {
  useMemberships,
  useMembershipDelete,
  useMembershipCreate,
  useMembershipUpdate,
} from '../compositions/useMemberships';
import { MembershipManagementTable, AddEditMembershipModal } from '../components';
import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { DeleteWarningIcon } from '@/assets/icons';
import { ROLES } from '../roles';

import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';
import { ALLOWED_ORDERS_BUSINESS_IDS } from '@/modules/pageLayout/components/NavigationMenu.vue';
import { ROLE_TYPE } from '@/modules/users/constants';
import {
  PUR_INVITE_PERMISSION_ADMIN,
  PUR_INVITE_PERMISSION_MANAGER,
  PUR_INVITE_PERMISSION_OWNER,
  PUR_INVITE_PERMISSION_PAYMENTS_MANAGER,
  PUR_INVITE_PERMISSION_PM_ORDERER,
} from '../constants';

export default {
  name: 'MembershipManagement',
  components: { MembershipManagementTable, Button, AddEditMembershipModal, DeleteWarningIcon },
  setup() {
    const root = getCurrentInstance().proxy;
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const { currentTenant } = useTenancy();
    const {
      memberships,
      loading: membershipsLoading,
      refetch,
      onResult,
    } = useMemberships(computed(() => ({ getParams: { businessId: currentTenant.value.id } })));
    const {
      deleteMembership,
      onDone: deleteMembershipOnDone,
      onError: deleteMembershipOnError,
    } = useMembershipDelete();
    const {
      createMembership,
      onDone: createMembershipOnDone,
      onError: createMembershipOnError,
    } = useMembershipCreate();
    const {
      updateMembership,
      onDone: updateMembershipOnDone,
      onError: updateMembershipOnError,
    } = useMembershipUpdate();
    const showAddEditMembership = ref(false);
    const showDeleteDialog = ref(false);
    const deleteRowIndex = ref(null);
    const editRowIndex = ref(null);
    const actionName = ref(null);
    const isActionLoading = ref(false);
    const refetchLoading = ref(false);

    const membershipNodes = computed(() => memberships.value.nodes?.filter(({ user }) => user));
    const excludeRolesForRestaurant = [ROLE_TYPE.EMPLOYEE];

    const allowedRoles = computed(() => {
      switch (currentTenant.value.type) {
        case 'SERVICE_PROVIDER':
          return [ROLES[0]];
        case 'RESTAURANT':
          return Object.values(ROLE_TYPE).filter((role) => {
            const currentTenantAllowedForOrderer = ALLOWED_ORDERS_BUSINESS_IDS.includes(currentTenant.value.id);
            if (excludeRolesForRestaurant.includes(role)) {
              return false;
            }
            if (currentTenantAllowedForOrderer) {
              return role !== ROLE_TYPE.ADMIN;
            } else {
              return role !== ROLE_TYPE.ADMIN && role !== ROLE_TYPE.ORDERER;
            }
          });
        case 'BOOK_KEEPING':
          return [ROLES[1]];
        default:
          return [];
      }
    });

    const handleDoneAction = (messageToConfirm, modalStateToToggle = undefined) => {
      root.$message.success(messageToConfirm);
      if (modalStateToToggle) {
        modalStateToToggle.value = false;
      }
      isActionLoading.value = false;
      refetchLoading.value = true;
      refetch();
    };

    const handleErrorAction = () => {
      isActionLoading.value = false;
      refetchLoading.value = false;
    };

    onResult(() => (refetchLoading.value = false));

    deleteMembershipOnDone(() =>
      handleDoneAction(
        root.$t('memberships.membershipTable.deletePermission.successMessage', { name: actionName.value }),
        showDeleteDialog
      )
    );

    createMembershipOnDone(() =>
      handleDoneAction(
        root.$t('memberships.membershipTable.addEditMembershipModal.successMessage', { name: actionName.value }),
        showAddEditMembership
      )
    );

    updateMembershipOnDone(() =>
      handleDoneAction(
        root.$t('memberships.membershipTable.updatePermissionSuccessMessage', { name: actionName.value }),
        showAddEditMembership
      )
    );

    deleteMembershipOnError(handleErrorAction);
    createMembershipOnError(handleErrorAction);
    updateMembershipOnError(handleErrorAction);

    const tableLoading = computed(() => {
      return membershipsLoading.value || refetchLoading.value;
    });

    const onSelectDeleteIndex = (index) => {
      deleteRowIndex.value = index;
      showDeleteDialog.value = true;
    };

    const onSelectEditIndex = (index) => {
      editRowIndex.value = index;
      showAddEditMembership.value = true;
    };

    const onMembershipDelete = () => {
      const id = membershipNodes.value[deleteRowIndex.value].id;
      const name = `${membershipNodes.value[deleteRowIndex.value].user.firstName} ${
        membershipNodes.value[deleteRowIndex.value].user.lastName
      }`;
      isActionLoading.value = true;
      actionName.value = name;

      deleteMembership({ id });
    };

    const onMembershipUpdate = ({ role, rowIndex }) => {
      const row = membershipNodes.value[rowIndex];
      isActionLoading.value = true;
      actionName.value = `${row.user.firstName} ${row.user.lastName}`;
      refetchLoading.value = true;

      updateMembership({
        updateParams: {
          id: row.id,
          role,
        },
      });
    };

    const onAddEditMembership = ({ firstName, lastName, email, role, editIndex }) => {
      isActionLoading.value = true;
      actionName.value = `${firstName} ${lastName}`;

      if (editIndex !== null) {
        onMembershipUpdate({ role, rowIndex: editIndex });
      } else {
        const requestPayload = {
          createParams: {
            businessId: currentTenant.value.id,
            role,
            invite: {
              firstName,
              lastName,
              email,
            },
          },
        };

        createMembership(requestPayload);
      }
    };

    const onAddEditMembershipModalClose = () => {
      showAddEditMembership.value = false;
      editRowIndex.value = null;
    };

    const isUserPermitted = computed(() =>
      [
        PUR_INVITE_PERMISSION_PM_ORDERER,
        PUR_INVITE_PERMISSION_MANAGER,
        PUR_INVITE_PERMISSION_PAYMENTS_MANAGER,
        PUR_INVITE_PERMISSION_ADMIN,
        PUR_INVITE_PERMISSION_OWNER,
      ].some((permission) => isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST[permission], true).value)
    );

    return {
      memberships: membershipNodes,
      showAddEditMembership,
      showDeleteDialog,
      deleteRowIndex,
      editRowIndex,
      allowedRoles,
      onMembershipDelete,
      onSelectDeleteIndex,
      onAddEditMembership,
      onMembershipUpdate,
      tableLoading,
      isActionLoading,
      currentTenant,
      isUserPermitted,
      onSelectEditIndex,
      onAddEditMembershipModalClose,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .delete-dialog {
    .el-dialog {
      border-radius: 0.5rem;
    }
  }
}
.delete-dialog-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>
