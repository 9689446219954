import Vue from 'vue';
import { ApolloClient, InMemoryCache, defaultDataIdFromObject, ApolloLink, createHttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import VueApollo from '@vue/apollo-option';
import { Auth } from 'aws-amplify';
import config from '@/config';
import * as Sentry from '@sentry/vue';
import router from '@/router';
Vue.use(VueApollo);

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: config.VUE_APP_API_URI,
});

const authLink = setContext(async (_, { headers }) => {
  const { idToken } = await Auth.currentSession();
  return {
    headers: {
      ...headers,
      authorization: idToken?.jwtToken ? `Bearer ${idToken.jwtToken}` : '',
    },
  };
});

const featureFlagsLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    'x-order-shekels': true,
    'x-document-shekels': true,
    'x-payment-negative-amount-allowed': true,
  },
}));

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors?.some((gqlError) => gqlError.extensions?.response?.status === 401)) {
      localStorage.removeItem('refreshToken');
      sessionStorage.removeItem('token');
      if (router.currentRoute.name !== 'signin') router.push({ name: 'signin' });
    }

    graphQLErrors.forEach(({ message, path, extensions }) => {
      Sentry.captureMessage(`[GraphQL error]: Message: ${message}, Path: ${path}`, {
        level: Sentry.Severity.Error,
        extra: extensions,
      });
    });
  }
  if (networkError) Sentry.captureException(networkError);
});

const cache = new InMemoryCache({
  freezeResults: true,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'Imbalance':
        return null;
      case 'StructureTemplate':
        return object.id || `StructureTemplateId:${object.supplierId}_${object.structureToken}_${object.documentType}`;
      case 'WorksAt':
        return null;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const offlineCheckMiddleware = new ApolloLink((operation, forward) => {
  if (navigator.onLine) {
    return forward(operation);
  }
  return;
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([offlineCheckMiddleware, authLink, featureFlagsLink, logoutLink, httpLink]),
  cache,
  defaultOptions,
  connectToDevTools: true,
  assumeImmutableResults: true,
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
