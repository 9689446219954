import { reactive, computed } from 'vue';
import { useUserPermissions } from '@/modules/auth';
import { USER_PERMISSIONS_LIST } from '@/permissions';

const permissionsInitialState = {
  hasPaymentView: null,
  hasPaymentInitiate: null,
  hasPaymentDocument: null,
  hasPaymentManageTasks: null,
  hasPaymentManageBankAccounts: null,
  hasPaymentManageCreditCards: null,
  hasPaymentEditDocumentedEvent: null,
  hasPaymentDeleteDocumentedEvent: null,
  hasPaymentExtended: null,
  hasReconciliationManage: null,
  hasReconciliationApprove: null,
  hasPurchasePaymentTermManage: null,
  hasPurchaseDeliveryManager: null,
  hasPurchaseOrderEdit: null,
  hasPurchaseView: null,
  hasRequestManage: null,
  hasBillingManage: null,
  hasPaymentOtherDestination: null,
};

const permissionsState = reactive({ ...permissionsInitialState });

const permissionMappings = {
  hasPaymentView: USER_PERMISSIONS_LIST.PAYMENT.VIEW,
  hasPaymentInitiate: USER_PERMISSIONS_LIST.PAYMENT.INITIATE,
  hasPaymentDocument: USER_PERMISSIONS_LIST.PAYMENT.DOCUMENT,
  hasPaymentManageTasks: USER_PERMISSIONS_LIST.PAYMENT.MANAGE_PAYMENT_TASKS,
  hasPaymentManageBankAccounts: USER_PERMISSIONS_LIST.PAYMENT.MANAGE_BANK_ACCOUNTS,
  hasPaymentManageCreditCards: USER_PERMISSIONS_LIST.PAYMENT.MANAGE_CREDIT_CARDS,
  hasPaymentEditDocumentedEvent: USER_PERMISSIONS_LIST.PAYMENT.EDIT_DOCUMENTED_EVENT,
  hasPaymentDeleteDocumentedEvent: USER_PERMISSIONS_LIST.PAYMENT.DELETE_DOCUMENTED_EVENT,
  hasPaymentExtended: USER_PERMISSIONS_LIST.PAYMENT.EXTENDED,
  hasReconciliationManage: USER_PERMISSIONS_LIST.RECONCILIATION.MANAGE,
  hasReconciliationApprove: USER_PERMISSIONS_LIST.RECONCILIATION.APPROVE,
  hasPurchasePaymentTermManage: USER_PERMISSIONS_LIST.PURCHASE.PAYMENT_TERM_MANAGE,
  hasPurchaseDeliveryManager: USER_PERMISSIONS_LIST.PURCHASE.DELIVERY_MANAGER,
  hasPurchaseOrderEdit: USER_PERMISSIONS_LIST.PURCHASE.ORDER_EDIT,
  hasPurchaseView: USER_PERMISSIONS_LIST.PURCHASE.VIEW,
  hasRequestManage: USER_PERMISSIONS_LIST.REQUEST.MANAGE,
  hasBillingManage: USER_PERMISSIONS_LIST.BILLING.MANAGE,
  hasPaymentOtherDestination: USER_PERMISSIONS_LIST.PAYMENT.OTHER_DESTINATION,
};

const useGlobalPermissions = function () {
  const { isUserPermittedForActiveTenant } = useUserPermissions();

  for (const [stateKey, permissionKey] of Object.entries(permissionMappings)) {
    permissionsState[stateKey] = isUserPermittedForActiveTenant(permissionKey);
  }

  const computedPermissions = {};
  for (const key in permissionsInitialState) {
    computedPermissions[key] = computed(() => permissionsState[key]);
  }

  return computedPermissions;
};

export default useGlobalPermissions;
